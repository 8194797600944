//TODO extract Clocks API to a separate module

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { Reminder } from '../API';
import { useJsonAsyncStorage } from '../Utils';
import { apiCall, apiGet, apiPost } from './ApiUtils';
import { CalendarReq, CalendarResponse } from './model/CalendarData';
import Exercize from './model/Exercise';
import ExercizeStats from './model/ExerciseStats';
import { LiveSessionData } from './model/LiveSession';
import PushInfo from './model/PushInfo';
import ReportData from './model/ReportData';
import RequestLogin from './model/RequestLogin';
import UserCreate from './model/UserCreate';
import UserEdit from './model/UserEdit';
import UserPassword from './model/UserPassword';
import UserProfile from './model/UserProfile';
import Constants from 'expo-constants';

const HOST = 'http://localhost:8080/';
const HOST2 = 'https://api.med.senopi.com/';
const HOST3 = 'https://api.pt.senopi.com/';

const backend = {
  local: {
    USERS: HOST + '',
    TOKEN_INFO: HOST + '',
    SCHEDULER: HOST + '',
    CLOCKER: HOST + '',
    EVENTS: HOST + '',
    REPORTS: HOST + '',
  },

  localFull: {
    CLOCKER: 'http://localhost:18000/',
    EVENTS: 'http://localhost:18001/',
    SCHEDULER: 'http://localhost:18002/',
    REPORTS: 'http://localhost:18003/',
    USERS: 'http://localhost:18004/',
    TOKEN_INFO: 'http://localhost:18005/',
  },

  production: {
    USERS: HOST2 + 'sen-users/',
    // USERS: HOST + '',
    TOKEN_INFO: HOST2 + 'sen-token-info/',
    SCHEDULER: HOST2 + 'sen-scheduler/',
    CLOCKER: HOST2 + 'sen-clocker/',
    EVENTS: HOST2 + 'sen-events/',
    REPORTS: HOST2 + 'sen-reports/',
    LIVE_SESSIONS: HOST2 + 'sen-ws/sessions/',
  },

  test: {
    USERS: HOST3 + 'sen-users/',
    // USERS: HOST + '',
    TOKEN_INFO: HOST3 + 'sen-token-info/',
    SCHEDULER: HOST3 + 'sen-scheduler/',
    CLOCKER: HOST3 + 'sen-clocker/',
    EVENTS: HOST3 + 'sen-events/',
    REPORTS: HOST3 + 'sen-reports/',
    LIVE_SESSIONS: HOST3 + 'sen-ws/sessions/',
  },
}
console.log(Constants.expoConfig)
console.log(Constants)

const config = backend[Constants.manifest.extra.BACKEND_ENV]; //See webpack.DefinePlugin
// const config = configLocal
// const config = configLocalFull

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyATx5K_AWhFCAmOiqIVEUlctHHrjurrsRQ',
    authDomain: 'senopimed-push-test.firebaseapp.com',
    projectId: 'senopimed-push-test',
    storageBucket: 'senopimed-push-test.appspot.com',
    messagingSenderId: '702738043250',
    appId: '1:702738043250:web:dd6e91ffdcda5749ad0deb',
  };

  // Initialize Firebase
  return initializeApp(firebaseConfig);
};

const getCloudToken = async () => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  const messaging = getMessaging();
  return await getToken(messaging, {
    vapidKey:
      'BLUWoCjicLAwMJ05K_ChWWqqRojZJkb3FNLRhf9937eBqlxo6hAknSfsunzDz4jo3l_JWwBbOIorDOCRFSAn-mI',
    serviceWorkerRegistration: await navigator.serviceWorker.ready,
  });
};

export default async (alias?: UserProfile) => {
  let storage = useJsonAsyncStorage('@senopi-user');
  let user: UserProfile | undefined = alias || ((await storage.getItem()) as UserProfile);

  const api = {
    userInfo: user,

    login: async (req: RequestLogin) => {
      let userResponse = await apiPost<UserProfile>(config.USERS + 'login', req);
      await storage.setItem(userResponse as UserProfile);
      user = userResponse;
      return userResponse;
    },

    logout: () => {
      storage.removeItem();
      user = undefined;
    },

    users: async () =>
      apiGet<UserProfile[]>(config.USERS + 'users/' + user?.username + '/' + user?.token),
    subadmins: async () =>
      apiGet<UserProfile[]>(config.USERS + 'subadmins/' + user?.username + '/' + user?.token),
    headsets: async () =>
      apiGet<{ headset: UserProfile; user?: UserProfile }[]>(
        config.USERS + 'headsets/' + user?.username + '/' + user?.token,
      ),
    headsetsSubadmins: async () =>
      apiGet<{ headset: UserProfile; user?: UserProfile }[]>(
        config.USERS + 'headsets-subadmins/' + user?.username + '/' + user?.token,
      ),
    assignUser: async (headset: string, user2: string) =>
      apiGet(
        config.USERS +
          'headset-assign-user/' +
          user?.username +
          '/' +
          user?.token +
          '/' +
          user2 +
          '/' +
          headset,
      ),
    assignHeadset: async (headset: string, user2: string) =>
      apiGet(
        config.USERS +
          'headset-assign-subadmin/' +
          user?.username +
          '/' +
          user?.token +
          '/' +
          user2 +
          '/' +
          headset,
      ),

    userRestore: async (email: string) =>
      apiGet<UserProfile>(config.USERS + 'user/restore/' + email),

    userReset: async (req: ResetPassword) =>
      apiPost<UserProfile>(config.USERS + 'user/reset', req),

    usersCreate: async (req: UserCreate) =>
      apiPost<UserProfile>(config.USERS + 'user/' + user?.username + '/' + user?.token, req),

    usersCreateMany: async (req: UserProfile[]) =>
      apiPost<UserProfile>(config.USERS + 'user/many/' + user?.username + '/' + user?.token, {users: req}),

    updateUserProfile: async (req: UserProfile) =>
      apiPost<UserProfile>(
        config.USERS + 'updateUser/' + user?.username + '/' + user?.token + '/' + req.username,
        req,
      ),
      
    updateUser: async (username: string, req: UserEdit) =>
      apiPost<UserProfile>(
        config.USERS + 'updateUser/' + user?.username + '/' + user?.token + '/' + username,
        req,
      ),

    updateAccount: async (req: UserProfile) =>
      apiPost<UserProfile>(
        config.USERS + 'updateAccount/' + user?.username + '/' + user?.token,
        req,
      ),

    updateSubadmin: async (username: string, req: UserEdit) =>
      apiPost<UserProfile>(
        config.USERS + 'updateSubadmin/' + user?.username + '/' + user?.token + '/' + username,
        req,
      ),
    updateHeadset: async (username: string, req: UserEdit) =>
      apiPost<UserProfile>(
        config.USERS + 'updateHeadset/' + user?.username + '/' + user?.token + '/' + username,
        req,
      ),
    updatePassword: async (req: UserPassword) =>
      apiPost<UserProfile>(
        config.USERS + 'updatePassword/' + user?.username + '/' + user?.token,
        req,
      ),

    exercises: async () =>
      apiGet<Array<Exercize>>(
        config.EVENTS + 'events/average/' + user?.username + '/' + user?.token,
      ),
    exercisesStats: async () =>
      apiGet<ExercizeStats>(config.EVENTS + 'events/stats/' + user?.username + '/' + user?.token),
    eventsAdminReport: () =>
      config.EVENTS + 'events/reportAdmin/' + user?.username + '/' + user?.token + '/xlsx',
    eventsReport: () =>
      config.EVENTS + 'events/report/' + user?.username + '/' + user?.token + '/xlsx',

    scheduleAdd: async (schedule: { [key: string]: Reminder }) =>
      apiCall(
        'POST',
        config.SCHEDULER + 'add-schedule/' + user?.username + '/' + user?.token,
        schedule,
      ),
    scheduleAllow: async () =>
      apiPost<{ schedule: { [key: string]: Reminder } }>(
        config.SCHEDULER + 'allow/' + user?.username + '/' + user?.token,
      ),
    scheduleGet: async () =>
      apiGet<{ schedule: { [key: string]: Reminder } }>(
        config.SCHEDULER + 'schedule/' + user?.username + '/' + user?.token,
      ),

    addDevice: async (deviceid: string) =>
      apiCall('POST', config.CLOCKER + 'add-device/' + user?.username + '/' + user?.token, {
        deviceid: deviceid,
      }),
    allowPush: async () =>
      apiPost<PushInfo>(config.CLOCKER + 'allow/' + user?.username + '/' + user?.token),

    reportSend: async (req: ReportData) =>
      apiPost<ReportData>(config.REPORTS + 'send/' + user?.username + '/' + user?.token, req),
    reportList: async () =>
      apiGet<Array<ReportData>>(config.REPORTS + 'list/' + user?.username + '/' + user?.token),

    liveSessions: async () =>
      apiGet<Array<LiveSessionData>>(config.LIVE_SESSIONS + user?.username + '/' + user?.token),
    // getData: (apiEndpoint: string) => {
    //     const url = new URL(CLOCKS_HOST + "reportCsv")
    //     url.search = new URLSearchParams({apiEndpoint: apiEndpoint}).toString()
    //     return url.toString()
    // }

    calendar: async (req?: CalendarReq) =>
      apiGet<CalendarResponse>(
        config.EVENTS + 'events/calendar/' + user?.username + '/' + user?.token,
        req,
      ),

    allowReminders: async () => {},
  };

  api.allowReminders = async () => {
    try {
      initFirebase();
      const token = await getCloudToken();
      if (token) {
        console.log(token);
        await navigator.serviceWorker.ready;
        Notification.requestPermission().then(async (result) => {
          if (result === 'granted') {
            try {
              await Promise.all([api.allowPush(), api.addDevice(token)]);
              console.log('Device ID stored');

              //Move schedule to backend
              const storageReminders = useJsonAsyncStorage('@reminders');
              const reminders = await storageReminders.getItem();
              if (reminders) {
                await api.scheduleAdd(reminders as { [key: string]: Reminder });
                await api.scheduleAllow();
                console.log('Schedule sent to backend');
                storageReminders.removeItem();
              }
            } catch (e) {
              console.error(`Device ID store failed:\n${e}`);
            }
          }
        });
      } else {
        console.log('Token not available! Or schedules already sent');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return api;
};
