import { useFonts } from 'expo-font';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import { FC, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import AdverseReportScreen from './AdverseReportScreen';
import HomeScreen from './HomeScreen';
import LoginScreen from './LoginScreen';
import { HasNavigation } from './Navigation';
import ProfileScreen from './PerformanceScreen';
import ProgressScreen from './ProgressScreen';
import { useJsonAsyncStorage } from './Utils';
import AdminCreateUserScreen from './admin/AdminCreateUserScreen';
import AdminHomeScreen from './admin/AdminHomeScreen';
import AdminUsersListScreen from './admin/AdminUsersListScreen';
import SenopiApi from './api/SenopiApi';
import { LangType } from './lang/lang';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import SubAdverseReportsListScreen from './subadmin/SubAdverseReportsListScreen';
import SubCreateUserScreen from './subadmin/SubCreateUserScreen';
import SubEditUserScreen from './subadmin/SubEditUserScreen';
import SubEditVrScreen from './subadmin/SubEditVrScreen';
import SubExerciseInfo from './subadmin/SubExerciseInfo';
import SubHeadsetAssignAdminScreen from './subadmin/SubHeadsetAssignAdminScreen';
import SubHeadsetAssignUserScreen from './subadmin/SubHeadsetAssignUserScreen';
import SubHomeScreen from './subadmin/SubHomeScreen';
import SubLiveSessionScreen from './subadmin/SubLiveSessionScreen';
import SubUsersListScreen from './subadmin/SubUsersListScreen';
import SubVrListScreen from './subadmin/SubVrListScreen';
import SuperviserHomeScreen from './superviser/SuperviserHomeScreen';
import SuperviserStartSession from './superviser/SuperviserStartSession';
import SuperviserUsersScreen from './superviser/SuperviserUsersScreen';
import { NavigationContainer, ParamListBase, RouteConfig, RouteProp, useRoute } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import NewPasswordScreen from './screens/NewPasswordScreen';

const Stack = createNativeStackNavigator()
let Dialog: any = null;
let dialogStyle: StyleProp<ViewStyle> = null;

const App = ({route}: {route: RouteProp<ParamListBase>}) => {
  const [fontsLoaded] = useFonts({
    SegoeLight: require('../assets/fonts/SegoeUI-Light.ttf'),
    SegoeBold: require('../assets/fonts/SegoeUI-Bold.ttf'),
    SegoeRegular: require('../assets/fonts/SegoeUI.ttf'),
    SegoeSemibold: require('../assets/fonts/SegoeUI-Semibold.ttf'),
    SFProSemibold: require('../assets/fonts/SFPro-Semibold.ttf'),
  });

  const screens: { [key: string]: FC<HasNavigation & any> } = {
    login: LoginScreen,
    resetPassword: ResetPasswordScreen,
    home: HomeScreen,
    performance: ProfileScreen,
    progress: ProgressScreen,
    adverseReport: AdverseReportScreen,
    //Admin
    adminListUsers: AdminUsersListScreen,
    adminCreateUser: AdminCreateUserScreen,
    adminHome: AdminHomeScreen,
    //Subadmin
    subadminHome: SubHomeScreen,
    subadminVr: SubVrListScreen,
    subadminUsers: SubUsersListScreen,
    // "subadminSubadmins": SubUsersListScreen,
    subadminCreateUser: SubCreateUserScreen,
    // "subadminCreateSubadmin": SubCreateUserScreen,
    subadminHeadsetAssignAdmin: SubHeadsetAssignAdminScreen,
    subadminHeadsetAssignUser: SubHeadsetAssignUserScreen,
    subadminAdverseReports: SubAdverseReportsListScreen,
    subadminSubadminEdit: SubEditUserScreen,
    subadminUserEdit: SubEditUserScreen,
    subadminVrEdit: SubEditVrScreen,
    subadminLiveSession: SubLiveSessionScreen,
    subadminExerciseInfo: SubExerciseInfo,
    supervisorHome: SuperviserHomeScreen,
    superviserUsers: SuperviserUsersScreen,
    superviserStartSession: SuperviserStartSession,
  };

  const [navigationStack, setNavigationStack] = useState<{ name: string; params?: any }[]>([]);
  const [navigate, setNavigation] = useState<{ name: string; params?: any }>({ name: 'login' });
  const [appLoaded, setAppLoaded] = useState(false);

  const navigation = (s: string|any, allowBack?: boolean, params?: any) => {
    if (allowBack) {
      navigationStack.push(navigate);
    }
    if ('..' == s && navigationStack.length > 0) {
      const navInfo = navigationStack.pop();
      s = navInfo?.name || 'home';
      params = { ...navInfo?.params, ...params };
    }
    setNavigation({ name: s, params: params });
    setNavigationStack([...navigationStack]);
  };

  const checkAuth = async () => {
    const api = await SenopiApi();
    const user = api.userInfo;
    const roles: { [key: string]: string } = {
      // 'admin': 'adminHome',
      admin: 'subadminHome',
      supervisor: 'supervisorHome',
      subadmin: 'subadminHome',
      user: 'home',
      placebo: 'home',
      login: 'login',
    };
    navigation(roles[user?.type || 'login'] || 'home');
    setAppLoaded(true);
    if (user?.type == 'placebo' || user?.type == 'user') {
      api.allowReminders();
    }
  };

  const langStorage = useJsonAsyncStorage('lang');

  const defaultLang = 'EN';
  const langFiles: { [key: string]: LangType } = {
    EN: require('./lang/en.json'),
    PL: require('./lang/pl.json'),
    DE: require('./lang/de.json'),
    FI: require('./lang/fi.json'),
    SE: require('./lang/se.json'),
  };

  const [lang, setLang] = useState(require('./lang/en.json'));

  const initLang = async () => {
    const lang = (await langStorage.getItem()) || defaultLang;
    const langData = Object.keys(langFiles[defaultLang]).map((key) => {
      return [key, langFiles[lang][key] ? langFiles[lang][key] : langFiles[defaultLang][key]];
    });

    setLang(Object.fromEntries(new Map(langData)));
  };

  const onLang = async (lang: string) => {
    langStorage.setItem(lang);
    const langData = Object.keys(langFiles[defaultLang]).map((key) => {
      return [key, langFiles[lang][key] ? langFiles[lang][key] : langFiles[defaultLang][key]];
    });

    setLang(Object.fromEntries(new Map(langData)));
  };

  const [isDialogShown, setDialogShown] = useState(false);

  const dialog = {
    showDialog: (dialog: any, style?: StyleProp<ViewStyle>) => {
      Dialog = dialog;
      dialogStyle = style;
      setDialogShown(true);
    },

    hideDialog: () => {
      Dialog = undefined;
      dialogStyle = undefined;
      setDialogShown(false);
    },

    showLoading: () => {
      Dialog = <ActivityIndicator color={'#FFFFFF'} />;
      dialogStyle = { backgroundColor: '' };
      setDialogShown(true);
    },

    hideLoading: () => {
      dialog.hideDialog();
    },
  };

  useEffect(() => {
    initLang();
    if(route.params?.resetToken) {
      navigation(NewPasswordScreen, true, {resetToken: route.params?.resetToken})
      setAppLoaded(true);
    } else {
      checkAuth();
    }
  }, []);

  const Screen = screens[navigate.name] || navigate.name;

  // const route = useRoute()
  // console.log("Navigation", route)
  return (
      <SafeAreaProvider>
        <View style={{ height: '100%' }}>
          {(fontsLoaded && appLoaded && (
            <LinearGradient colors={['#041F1B', '#072D37']} style={styles.container}>
              <Screen
                navigation={navigation}
                dialog={dialog}
                lang={lang}
                setLang={onLang}
                style={{ maxWidth: 600, width: '100%', height: '100%' }}
                {...navigate.params}
              />
              <StatusBar style='light' />
            </LinearGradient>
          )) || <Text>Loading...</Text>}
          {isDialogShown && Dialog && (
            <View style={styles.overlay}>
              <View style={[styles.dialog, dialogStyle]}>{Dialog}</View>
            </View>
          )}
        </View>
      </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  overlay: {
    position: 'absolute',
    backgroundColor: '#0000004F',
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-around',
  },

  dialog: {
    backgroundColor: '#FFFFFF',
    top: '15%',
    minWidth: 100,
    maxWidth: 400,
    maxHeight: '60%',
    padding: 16,
    borderRadius: 16,
  },
});

serviceWorkerRegistration.register();

const prefix = Linking.createURL('/');
console.log('Navigation prefix: %s', prefix);

const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      App: '',
      // ResetToken: '/:resetToken?',
    },
  },
};

export default () => (
  <NavigationContainer linking={linking} documentTitle={{ enabled: false }}>
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name='App' component={App} />
      {/* <Stack.Screen name='ResetToken' component={App} /> */}
    </Stack.Navigator>
  </NavigationContainer>
);
