import UserProfile from './UserProfile';

export enum Screen {
  CALIBRATION_01 = 'CALIBRATION_01',
  CALIBRATION_02 = 'CALIBRATION_02',
  MUSIC_SELECTION = 'MUSIC_SELECTION',
  ENVIRONMENT = 'ENVIRONMENT',
  WELCOME = 'WELCOME',
  OVERVIEW = 'OVERVIEW',
  INSTRUCTIONS = 'INSTRUCTIONS',
  RESULTS = 'RESULTS',
  TUTORIAL = 'TUTORIAL',
  GOODBYE = 'GOODBYE',
  GAME = 'GAME',
  FINAL_OVERVIEW = 'FINAL_OVERVIEW',
  POINTER_SELECTION = 'POINTER_SELECTION',
}

export interface ExerciseDetails {
  exerciseName: string;
  instructions: string;
  instructionsLang: {
    DE: string;
    PL: string;
    EN: string;
    FI: string;
    SE: string;
  };
  exerciseNameLang: {
    DE: string;
    PL: string;
    EN: string;
    FI: string;
    SE: string;
  };
}

export interface PerformanceInfo {
  timeLeft: number;
  correct: number;
  incorrect: number;
  misses: number;
}

export interface ExerciseInfo {
  userId: string;
  headsetId: string;
  exerciseInfo: ExerciseDetails;
  background: string;
  screen: Screen | null;
  performanceInfo: PerformanceInfo;
}

export interface LiveSessionData {
  exercise: ExerciseInfo;
  userAccessInfo: UserProfile;
}
