import { useAsyncStorage } from '@react-native-community/async-storage';
import { Dimensions } from 'react-native';
import UserProfile from './api/model/UserProfile';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

const useJsonAsyncStorage = (key: string) => {
  const { getItem, setItem, mergeItem, removeItem } = useAsyncStorage(key);
  return {
    getItem: async () => {
      const result = await getItem();
      return result ? JSON.parse(result) : undefined;
    },

    setItem: (value: any) => setItem(JSON.stringify(value)),
    mergeItem: (value: any) => mergeItem(JSON.stringify(value)),
    removeItem: () => removeItem(),
  };
};

function uniqBy<T>(a: T[], key: (item: T) => any) {
  return [...new Map(a.map((x) => [key(x), x])).values()];
}

function userFullName(user: UserProfile) {
  return [user.firstName, user.lastName].filter((x) => x).join(' ') || user.username;
}

function userFullNameMasterboard(user: UserProfile) {
  let name = user.username;
  const fullName = [user?.firstName, user?.lastName].filter((x) => x).join(' ');
  if (fullName) {
    name += ' (' + fullName + ')';
  }
  return name;
}

function userFullNameLiveSession(user?: UserProfile) {
  let name = '';
  const fullName = [user?.lastName, user?.firstName].filter((x) => x).join(', ');
  if (fullName) {
    name += fullName;
  }
  return name ?? user?.username ?? '';
}

function usernameFirstLetter(user: UserProfile) {
  return getUsername(user)?.charAt(0) || 'S';
}

function headsetFullName(user: UserProfile) {
  //PA7950RGF9270575B
  //PA79***575B
  //PA79575B
  let name = [user?.firstName, user?.lastName].filter((x) => x).join(' ');
  const serial = user.username.toUpperCase(); //(user.username.length > 8 ? user.username.substring(0, 4) + '***' + user.username.substring(user.username.length - 4) : user.username).toUpperCase()
  name = name ? name + ' (' + serial + ')' : serial;
  return name;
}

function secondsToMinutesSeconds(time: number) {
  const minutes = Math.floor(time / 60) || '00';
  const seconds = Math.floor(time % 60) || '00';

  return `${minutes}:${seconds > 0 && seconds < 10 ? `0${seconds}` : seconds}`;
}

function getUsername(user: UserProfile, withoutLogin = false) {
  return user?.privateData?.username
    ? withoutLogin
      ? user.privateData.username
      : `${user.privateData.username} (${user.username})`
    : user.username;
}

function capitalizeFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export {
  useJsonAsyncStorage,
  uniqBy,
  userFullName,
  headsetFullName,
  userFullNameMasterboard,
  userFullNameLiveSession,
  usernameFirstLetter,
  secondsToMinutesSeconds,
  getUsername,
  capitalizeFirst,
  screenWidth,
  screenHeight,
};
