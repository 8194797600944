import { useEffect, useState } from 'react';
import { Image, ImageSourcePropType, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';
import { userFullNameMasterboard } from '../Utils';

const UserProfileInfo = ({ user, lang, actions }: { user: UserProfile, lang: any, actions?: Array<ListAction> }) => {

    const userLabel = userFullNameMasterboard(user)
    const info: Array<string> = []
    if (!user.token) {
        info.push(lang.users_profile_inactive)
    }

    if (user.type == 'placebo') {
        info.push(lang.users_profile_placebo)
    }

    return <View style={styles.user_profile}>
        <Image source={require('../../assets/icon.png')} style={styles.user_photo} resizeMode='cover' />
        <View style={{ flexGrow: 1 }}>
            <Text style={styles.user_label}>{userLabel}</Text>
            <Text style={styles.user_sublabel}>{info.join(', ')}</Text>
        </View>
        {user.token && actions?.map((a, i) => <TouchableOpacity style={styles.action} key={i} onPress={() => a.action(user)}><Image style={{ width: a.width, height: a.height }} source={a.icon} /></TouchableOpacity>)}
    </View>
}

interface UsersListProps extends HasNavigation {

    onSelectUser: (user: UserProfile) => void
    source: 'user' | 'subadmin' | 'placebo' | 'admin' | 'headset' | 'client'
    user?: UserProfile,
    actions?: Array<ListAction>
}

interface ListAction {
    icon: ImageSourcePropType,
    width: number,
    height: number,
    action: (user: UserProfile) => void
}

export default ({ actions, user, onSelectUser, source, style, lang, dialog }: UsersListProps) => {

    const [usersFetch, setUsersFetch] = useState<UserProfile[]>([])
    const [users, setUsers] = useState<UserProfile[]>([])

    const loadList = async () => {
        dialog.showLoading()
        const api = await SenopiApi(user)

        let result = source == 'subadmin' ? await api.subadmins() : await api.users()
        if (api.userInfo.type == 'admin' && (source == 'user' || source == 'placebo')) {
            result = result.map(x => { x.firstName = x.username; return x }).map(x => { x.lastName = undefined; return x })
        }
        setUsers(result)
        setUsersFetch(result)
        dialog.hideLoading()
    }

    const search = (text: string) => {
        setUsers(usersFetch.filter(x => {
            return x.firstName?.match(`.*${text}.*`) || x.lastName?.match(`.*${text}.*`) || x.username?.match(`.*${text}.*`)
        }))
    }

    useEffect(() => {
        loadList()
    }, [])

    return (
        <View style={[styles.container, style]}>
            <View style={styles.search}>
                <Image source={require('../../assets/icon_search.png')} style={styles.search_icon} />
                <TextInput style={styles.search_input} placeholder={lang.users_search} onChangeText={search} />
            </View>
            <View style={styles.list}>
                {users.map(x => <ListItem key={x.username} >
                    <TouchableOpacity onPress={() => onSelectUser(x)}>
                        <UserProfileInfo user={x} lang={lang} actions={actions} />
                    </TouchableOpacity>
                </ListItem>)}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        marginBottom: 15,
        textAlign: "center"
    },

    list: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: 'stretch',
    },

    create_user: {
        marginTop: 23,
        marginBottom: 18
    },

    user_profile: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    user_photo: {
        width: 50,
        height: 50,
        borderWidth: 2,
        borderColor: '#48A9C5',
        borderRadius: 60
    },

    user_label: {
        color: "#FFFFFF",
        opacity: 0.8,
        fontSize: 20,
        fontFamily: "SegoeRegular",
        textAlign: "center",
        flexGrow: 1
    },

    user_sublabel: {
        color: "#FFFFFF",
        opacity: 0.5,
        fontSize: 15,
        fontFamily: "SegoeRegular",
        textAlign: "center",
        flexGrow: 1
    },

    search: {
        backgroundColor: "#0A100FB2",
        borderRadius: 23,
        flexDirection: "row",
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 16,
        paddingLeft: 16,
        marginBottom: 18
    },

    search_icon: {
        width: 21,
        height: 21,
        marginRight: 23
    },

    search_input: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        flexGrow: 1
    },

    action: {
        width: 26,
        height: 26,
        borderRadius: 26,
        backgroundColor: "#48A9C5",
        justifyContent: 'center',
        alignItems: "center"
    }

});

export { ListAction }