import { useEffect } from 'react';
import { Alert, Image, Linking, StyleSheet, View } from 'react-native';
import API, { ClocksAPI } from '../API';
import IconButton from '../elements/IconButton';
import SButton from '../elements/SButton';
import AdminStreamsCreate from '../model/CreateAdminStreams';
import { HasNavigation } from '../Navigation';

export default ({ navigation, style, lang }: HasNavigation) => {

    const initAdmin = async () => {
        const api = await API()
        api.batchCall(AdminStreamsCreate)   
    }

    const onLogout = async () => {
        const api = await API()
        await api.logout()
        navigation("login", false)
    }

    const requestData = async () => {
        const api = await API()
        const EXPORT_ACCESS = "Export Data"
        let token = (await api.accessList()).accesses.find(x => EXPORT_ACCESS == x.name)
        if(!token) {
            token = (await api.accessCreate({
                name: "Export Data",
                permissions: [
                    {streamId: 'tokens_read', level: 'read'},
                    {streamId: 'adverse_reports', level: 'read'}
                ]
            })).access
        }
        
        if(token.apiEndpoint) {
            const downloadUrl = ClocksAPI().getData(token.apiEndpoint)
            await Linking.openURL(downloadUrl)
        } else {
            Alert.alert("Unable to get Access to user data")
        }
    }

    useEffect(() => {
        initAdmin()
    }, [])

    return (
        <View style={[styles.container, style]}>
            <Image source={require('../../assets/logo_main.png')} style={{ width: 198, height: 50, marginTop: 48 }} />
            <View style={styles.form}>
                <SButton label={lang.admin_create_user} style={styles.form_button} onPress={() => navigation("adminCreateUser", true)} />
                <SButton label={lang.admin_list_users} style={styles.form_button} onPress={() => navigation("adminListUsers", true)} />
                <SButton label={lang.admin_download_data} style={styles.form_button} onPress={requestData} />
            </View>
            <IconButton label={lang.home_sign_out} icon={require('../../assets/icon_signout.png')} iconWidth={18} iconHeight={16} onPress={onLogout} />
            <View style={{ flexGrow: 1 }} />
            <Image source={require('../../assets/logo_developed_by.png')} resizeMode="contain" style={{ width: 97, height: 46, marginBottom: 10, marginTop: 10 }} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    photo: {
        marginTop: 48,
        width: 120,
        height: 120,
        borderWidth: 4,
        borderColor: '#48A9C5',
        borderRadius: 60
    },

    title: {
        color: "#48A9C5",
        fontSize: 29,
        fontFamily: "SegoeRegular",
        marginTop: 19
    },

    token: {
        color: "#FFFFFF",
        fontSize: 15,
        fontFamily: "SegoeRegular",
        marginTop: 5
    },

    form: {
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: 49,
        marginBottom: 46,
    },

    form_button: {
        marginBottom: 17
    }

});
