import moment from 'moment';
import { useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { CalendarData, CalendarResponse } from '../api/model/CalendarData';
import ExercizeStats from '../api/model/ExerciseStats';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import InfoCalendar from '../elements/InfoCalendar';
import SectionView from '../elements/SectionView';
import SText from '../elements/SText';
import { LangType } from '../lang/lang';
import { HasNavigation } from '../Navigation';
import { getUsername } from '../Utils';

interface ExerciseInfoData {
  date: string;
  time: string;
  exercises: Array<{ name: string; done: boolean }>;
}

interface Props extends HasNavigation {
  user: UserProfile;
}

const ExercisesInfo = ({
  lang,
  data,
}: {
  lang: LangType;
  data: Array<ExerciseInfoData>;
}): JSX.Element => {
  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={styles.row}>
        <Text style={styles.column_name}>{lang.superviser_user_info_date}</Text>
        <Text style={styles.column_name}>{lang.superviser_user_info_time}</Text>
        <Text style={styles.column_name}>{lang.superviser_user_info_exercises}</Text>
      </View>
      {data.map((value: ExerciseInfoData, valueIndex: number) => {
        return (
          <View key={`value-${value.time}-${valueIndex}`} style={styles.row}>
            <View style={{ flex: 3 }}>
              <SText center style={styles.date_text}>
                {value.date}
              </SText>
            </View>
            <View style={{ flex: 3 }}>
              <SText center style={styles.time_text}>
                {value.time}
              </SText>
            </View>
            <View style={[styles.exercises_container, { flex: 3 }]}>
              {value.exercises.map((exercise, exerciseIndex) => {
                return (
                  <View
                    key={`${exercise.name}-${exerciseIndex}`}
                    style={[
                      styles.exercise_name_container,
                      { backgroundColor: exercise.done ? '#FFFFFF' : '#777777' },
                    ]}
                  >
                    <SText style={styles.exercise_name}>{exercise.name.charAt(0)}</SText>
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default ({ user, lang, navigation, dialog, style }: Props) => {
  const [info, setInfo] = useState<ExercizeStats>({});
  const [calendarData, setCalendarData] = useState<CalendarResponse>({});
  const [exercisesData, setExercisesData] = useState<{
    [key: string]: { [key: string]: CalendarData[] };
  } | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const selectDateHandler = (date?: string) => setSelectedDate(date || '');

  const loadExercises = async () => {
    const api = await SenopiApi(user);

    const exercises = await api.exercisesStats();

    setInfo(exercises);
  };

  const loadCalendar = async () => {
    const api = await SenopiApi(user);

    const calendar = await api.calendar();

    const exercisesData: { [key: string]: { [key: string]: CalendarData[] } } = {};

    Object.keys(calendar).forEach((key: string) => {
      calendar[key].forEach((e) => {
        exercisesData[key] = {
          ...exercisesData[key],
          [e.sid]: calendar[key].filter((a) => a.sid === e.sid),
        };
      });
    });

    setCalendarData(calendar);
    setExercisesData(exercisesData);
  };

  useEffect(() => {
    const load = async () => {
      dialog.showLoading();
      await loadExercises();
      await loadCalendar();
      dialog.hideDialog();
    };
    load();
  }, []);

  const datesToMark = Object.keys(calendarData);

  const badges: {
    [key: string]: number;
  } = {};

  if (exercisesData) {
    Object.keys(exercisesData).forEach((date) => {
      badges[date] = Object.keys(exercisesData[date]).length;
    });
  }

  let exercisesInfoData: any[] = [];

  if (selectedDate && exercisesData && exercisesData[selectedDate]) {
    exercisesInfoData = Object.keys(exercisesData[selectedDate])
      .map((sid) => {
        const session = exercisesData[selectedDate][sid];
        console.log(session);
        return {
          date: moment(selectedDate).format('DD/MM/YYYY'),
          time: moment(Math.min(...session.map((e) => e.timestamp))).format('HH:mm'),
          exercises: [
            {
              name: 'W',
              done: session.filter((e) => e.exercise === 'warmup' || e.exercise === 'physical')
                .length,
            },
            { name: 'F', done: session.filter((e) => e.exercise === 'focus').length },
            { name: 'S', done: session.filter((e) => e.exercise === 'switch').length },
            { name: 'M', done: session.filter((e) => e.exercise === 'memory').length },
          ],
        };
      })
      .reverse();
  }

  return (
    <View style={[styles.container, style]}>
      <HeaderPane
        onBackPressed={() => navigation('..')}
        rightComponent={
          <TouchableOpacity style={{ padding: 14 }} onPress={() => {}}>
            <Image
              source={require('../../assets/share_icon.png')}
              style={{ width: 17, height: 21 }}
            />
          </TouchableOpacity>
        }
      />
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scroll_content_container}>
        <Text style={styles.overview_title}>{lang.superviser_user_info_overview}</Text>
        <SectionView style={{ marginVertical: 0 }}>
          <Text style={styles.first_name_text}>{getUsername(user, true)}</Text>
          <Text style={styles.username_text}>{user.username}</Text>
        </SectionView>
        <View style={styles.row}>
          <SectionView style={{ flex: 1, marginVertical: 0 }}>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_this_week}</Text>
            <Text style={styles.section_secondary_text}>{info.sessionsThisWeek}</Text>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_sessions}</Text>
          </SectionView>
          <SectionView style={{ flex: 1, marginVertical: 0 }}>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_overall}</Text>
            <Text style={styles.section_secondary_text}>{info.count}</Text>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_sessions}</Text>
          </SectionView>
          <SectionView style={{ flex: 1, marginVertical: 0 }}>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_overall}</Text>
            <Text style={styles.section_secondary_text}>{info.score}</Text>
            <Text style={styles.section_main_text}>{lang.superviser_user_info_balloons}</Text>
          </SectionView>
        </View>
        <SectionView style={{ marginTop: 10, marginBottom: 20, paddingHorizontal: 20, opacity: 1 }}>
          <InfoCalendar
            datesToMark={datesToMark}
            badges={badges}
            date={selectedDate}
            onDateClick={selectDateHandler}
            lang={lang}
          />
          {exercisesInfoData.length && <ExercisesInfo lang={lang} data={exercisesInfoData} />}
        </SectionView>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    opacity: 1,
  },
  scroll: {
    flexGrow: 1,
  },
  scroll_content_container: {
    paddingVertical: 20,
  },
  overview_title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 20,
    fontFamily: 'SegoeRegular',
    marginBottom: 20,
    textAlign: 'center',
  },
  first_name_text: {
    color: '#48A9C5',
    fontSize: 22,
    lineHeight: 26,
    fontFamily: 'SegoeRegular',
    marginBottom: 5,
    textAlign: 'center',
  },
  username_text: {
    color: '#868686',
    fontSize: 15,
    lineHeight: 18,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  section_main_text: {
    color: '#FFFFFF',
    fontSize: 17,
    lineHeight: 20,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },
  section_secondary_text: {
    color: '#48A9C5',
    fontSize: 32,
    lineHeight: 38,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },
  column_name: {
    flex: 1,
    color: '#48A9C5',
    fontSize: 15,
    lineHeight: 15,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },
  date_text: {
    color: '#FFFFFF',
    fontSize: 18,
    lineHeight: 22,
  },
  time_text: {
    color: '#FFFFFF',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  exercises_container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exercise_name: {
    color: '#091717',
    fontSize: 10,
    lineHeight: 15,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },
  exercise_name_container: {
    width: 18,
    height: 18,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 2,
  },
});
