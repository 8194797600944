import { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';
import { headsetFullName, userFullName } from '../Utils';
import { ListAction } from './SubUsersList';

interface Props extends HasNavigation {
    source: 'subadmin' | 'user'
    user?: UserProfile
}

const ItemInfo = ({ user, actions }: { user: {headset: UserProfile, user?: UserProfile}, actions?: Array<ListAction> }) => {

    const userLabel = user.user ? userFullName(user.user) + '(' + user.user.username + ')' : ''
    const headsetLabel = headsetFullName(user.headset)
    return <View style={styles.item}>
        <View style={styles.item_info}>
            <Text style={styles.item_info_title}>{headsetLabel}</Text>
            <Text style={styles.item_info_label}>{userLabel}</Text>
        </View>
        {actions?.map((a, i) => <TouchableOpacity style={styles.action} key={i} onPress={() => a.action(user.headset)}><Image style={{ width: a.width, height: a.height }} source={a.icon} /></TouchableOpacity>)}
        <Image source={require(user.user ? '../../assets/icon_user.png' : '../../assets/icon_plus.png')} style={[styles.item_button, {tintColor: (user.user ? undefined : "#48A9C5") }]} resizeMode={'center'} />
    </View>
}

export default ({ user, source, navigation, style, lang, dialog }: Props) => {

    const [users, setUsers] = useState<{headset: UserProfile, user?: UserProfile}[]>([])
    const [allowEdit, setAllowEdit] = useState(false)

    const loadList = async () => {
        dialog.showLoading()
        const api = await SenopiApi(user)

        const result = source == 'subadmin' ? await api.headsetsSubadmins() : await api.headsets()
        setUsers(result)
        dialog.hideLoading()
    }

    const initEdit = async () => {
        const api = await SenopiApi()
        setAllowEdit(api.userInfo.type == 'admin')
    }

    useEffect(() => {
        loadList()
        initEdit()
    }, [])

    const actions: Array<ListAction> = allowEdit ? [
        {
            icon: require("../../assets/icon_edit.png"),
            width: 14,
            height: 14,
            action: (user: UserProfile) => navigation("subadminVrEdit", true, {user: user})
        }
    ] : []


    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={[styles.title]} >{source == 'subadmin' ? lang.vr_title_subadmin : lang.vr_title}</Text>
                <View style={styles.list}>
                    {users.map(x => <ListItem key={x.headset.username} >
                        <TouchableOpacity onPress={() => navigation(source == 'subadmin' ? 'subadminHeadsetAssignAdmin' : "subadminHeadsetAssignUser", true, { user: user, headset: x })}>
                            <ItemInfo user={x} actions={actions} />
                        </TouchableOpacity>
                    </ListItem>)}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        marginBottom: 15,
        textAlign: "center"
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
    },

    list: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: 'stretch',
        marginTop: 30,
    },

    item: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    item_info: {
        flexDirection: 'column',
        alignContent: 'flex-start',
        flexGrow: 1
    },

    item_info_title: {
        color: "#48A9C5",
        opacity: 0.8,
        fontSize: 16,
        fontFamily: "SegoeRegular",
    },

    item_info_label: {
        color: "#FFFFFF",
        opacity: 0.8,
        fontSize: 20,
        fontFamily: "SegoeRegular",
    },

    item_button: {
        width: 24,
        height: 24,
    },

    action: {
        width: 26,
        height: 26,
        borderRadius: 26,
        backgroundColor: "#48A9C5",
        justifyContent: 'center',
        alignItems: "center",
        marginLeft: 10,
        marginRight: 10
    }
});
