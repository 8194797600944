import { useState } from 'react';
import { Image, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

interface Props {
  label: string;
  style?: StyleProp<ViewStyle>;
  placeholderText: string;
  zIndex: number;
  zIndexInverse: number;
  value: any | null;
  items: Array<{ label: string, value: any }>;
  onValueChange?: (value: {label: string, value: any}) => void;
}

const DropDown: React.FC<Props> = ({
  label,
  style,
  placeholderText,
  zIndex,
  value,
  items,
  onValueChange,
  zIndexInverse,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setValue] = useState(value);

  return (
    <View style={[style, { height: 51, zIndex }]}>
      <Text style={styles.label}>{label}</Text>
      <DropDownPicker
        containerStyle={styles.dropdown_content_container}
        style={styles.dropdown}
        placeholder={placeholderText}
        placeholderStyle={styles.label}
        textStyle={styles.label}
        ArrowUpIconComponent={() => (
          <Image
            source={require('../../assets/icon_arrow_down.png')}
            style={{ width: 9, height: 6, transform: [{ rotate: '180deg' }] }}
          />
        )}
        ArrowDownIconComponent={() => (
          <Image
            source={require('../../assets/icon_arrow_down.png')}
            style={{ width: 9, height: 6 }}
          />
        )}
        labelProps={{
          numberOfLines: 1,
        }}
        zIndex={zIndex}
        dropDownContainerStyle={styles.dropdown_container}
        listItemLabelStyle={styles.list_item}
        showTickIcon={false}
        open={open}
        value={currentValue}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        onSelectItem={onValueChange}
        zIndexInverse={zIndexInverse}
        dropDownDirection='BOTTOM'
      />
    </View>
  );
};

const styles = StyleSheet.create({
  dropdown_content_container: {
    borderWidth: 1,
    borderRadius: 9,
    width: undefined,
    borderColor: '#FFFFFFB3',
    alignItems: 'center',
    flexDirection: 'row',
    height: 52,
    marginTop: 10,
  },
  dropdown: {
    height: 51,
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 10,
  },
  dropdown_container: {
    backgroundColor: '#052221',
    opacity: 1,
    borderColor: '#FFFFFFB3',
    paddingBottom: 20,
  },
  label: {
    fontFamily: 'SegoeRegular',
    fontSize: 18,
    lineHeight: 22,
    color: '#FFFFFF',
  },
  list_item: {
    fontFamily: 'SegoeRegular',
    fontSize: 18,
    lineHeight: 22,
    color: '#FFFFFF',
    marginTop: 20,
    paddingHorizontal: 10,
  },
});

export default DropDown;
