import { useEffect, useState } from 'react';
import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import IconButton from '../elements/IconButton';
import LanguagePicker from '../elements/LanguagePicker';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
import LinkText from '../elements/LinkText';

interface Props extends HasNavigation {
    title?: string
    user?: UserProfile
}


export default ({ navigation, user, lang, setLang, style, ...rest }: Props) => {

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [type, setType] = useState<string>('')

    const [currentUser, setCurrentUser] = useState<UserProfile>(Object.assign(new UserProfile(), user))

    const onLogout = async () => {
        const api = await SenopiApi()
        api.logout()
        navigation("login", false)
    }

    const loadProfile = async () => {
        const api = await SenopiApi(user)
        if(!user) {
            setCurrentUser(Object.assign(new UserProfile(), api.userInfo))
        }
        try {
            const user = api.userInfo
            setFirstName(user.firstName || 'Anonymous')
            setLastName(user.lastName || '')
            setType(user.type || 'supervisor')
        } catch (e) {
            console.error(e)
        }
    }

    const eventsReport = async () => {
        const api = await SenopiApi(user)
        const downloadUrl = api.eventsReport()
        await Linking.openURL(downloadUrl)
    }

    useEffect(() => {
        loadProfile()
    }, [])

    return (
        <View style={[styles.container, style]}>
            {user && <HeaderPane onBackPressed={() => navigation("..")} />}
            <LanguagePicker lang={lang} setLang={setLang} navigation={navigation} dialog={rest.dialog} />
            <Image source={require('../../assets/logo_main.png')} style={{ width: 198, height: 50, marginTop: 48 }} />
            <Image source={require('../../assets/icon.png')} style={styles.photo} resizeMode='cover' />
            <Text style={styles.title} >{firstName} {lastName}</Text>
            <View style={styles.form}>
                <SButton label={lang.home_superviser_start_session} style={styles.form_button} onPress={() => navigation("superviserStartSession", true, { user: currentUser, source: 'supervisor' })} />
                <SButton label={lang.home_superviser_users} style={styles.form_button} onPress={() => navigation("superviserUsers", true, { user: currentUser, source: 'supervisor' })} />
            </View>
            <LinkText label={lang.home_superviser_report} onPress={eventsReport} />
            <View style={{ flexGrow: 1 }} />
            <IconButton label={lang.home_sign_out} icon={require('../../assets/icon_signout.png')} iconWidth={18} iconHeight={16} onPress={onLogout} />
            <View style={{ flexGrow: 1 }} />
            <Image source={require('../../assets/logo_developed_by.png')} resizeMode="contain" style={{ width: 97, height: 46, marginBottom: 10, marginTop: 10 }} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    photo: {
        marginTop: 48,
        width: 120,
        height: 120,
        borderWidth: 4,
        borderColor: '#48A9C5',
        borderRadius: 60
    },

    title: {
        color: "#48A9C5",
        fontSize: 29,
        fontFamily: "SegoeRegular",
        marginTop: 19
    },

    token: {
        color: "#FFFFFF",
        fontSize: 15,
        fontFamily: "SegoeRegular",
        marginTop: 5
    },

    form: {
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: 49,
        marginBottom: 46,
    },

    form_button: {
        marginBottom: 17
    },
});
