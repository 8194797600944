import { StyleProp, StyleSheet, Text, View, ViewProps, ViewStyle } from "react-native";
import { LangType } from "../lang/lang";

interface Props extends ViewProps {
    title: string,
    weeks?: Number,
    sessions?: Number,
    value?: Number,
    style?: StyleProp<ViewStyle>,
    lang: LangType,
}

export default ({ title, weeks, sessions, style, lang, ...rest }: Props) => {

    return (
        <View style={[styles.container, style]}>
            <Text style={styles.stats_item_label}>{title}</Text>
            <View style={styles.value_container}>
                <View style={[styles.stats_item_container]} {...rest}>
                    <Text style={styles.stats_item_value}>{weeks || 0}</Text>
                    <Text style={styles.stats_item_sub}>{lang.progress_main_weeks}</Text>
                </View>
                <View style={[styles.stats_item_container]} {...rest}>
                    <Text style={styles.stats_item_value}>{sessions || 0}</Text>
                    <Text style={styles.stats_item_sub}>{lang.progress_main_sessions}</Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({

    container: {
        flexDirection: "column",
        alignContent: "center",
    },

    value_container: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%"
    },

    stats_item_container: {
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        paddingStart: 10,
        paddingEnd: 10
    },

    stats_item_label: {
        color: "#48A9C5",
        fontFamily: "SegoeRegular",
        fontSize: 15,
        textAlign: "center"
    },

    stats_item_sub: {
        color: "#FFFFFFB2",
        fontFamily: "SegoeRegular",
        fontSize: 12,
    },

    stats_item_value: {
        color: "#FFFFFF",
        flexDirection: "row",
        fontFamily: "SegoeRegular",
        fontSize: 20,
        lineHeight: 27,
        marginBottom: -5
    }



})
