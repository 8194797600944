import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import UserProfile from '../api/model/UserProfile';
import Constants from 'expo-constants';
import { Buffer } from 'buffer';

interface ReturnType<T> {
    message: T | undefined
}

const useSocketIO = <T>(userInfo: UserProfile, eventName: string): ReturnType<T> => {
    const [message, setMessage] = useState<T>()

    useEffect(() => {
        const token = Buffer.from(userInfo.username + ':' + userInfo.token).toString('base64')
        const url = Constants.manifest.extra.BACKEND_ENV == 'test' ? 'https://api.senopi-test.hrbr.pro' : 'https://api.med.senopi.com'
        const socket = io(url, {
            path: '/sen-ws-ws/socket.io',
            query: {
                token: token
            },
            transports: ['websocket']
        })
        socket.open()

        socket.on(eventName, (data: T) => {
            setMessage(data)
        })

        return () => {
            socket.close()
        }
    }, [])

    return { message };
};

export default useSocketIO;
