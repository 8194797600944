import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import API, { EndpointAPI } from '../API';
import EventInfo from '../api/response/EventInfo';
import HeaderPane from '../elements/HeaderPane';
import LinkText from '../elements/LinkText';
import ListItem from '../elements/ListItem';
import STabBar2 from '../elements/STabBar2';
import { HasNavigation } from '../Navigation';
import { uniqBy } from '../Utils';
import EventsExample1 from '../model/EventsExample1.json';
import EventsExample2 from '../model/EventsExample2.json';

export default ({ navigation, style, lang }: HasNavigation) => {

    const [tokensRead, setTokensRead] = useState<EventInfo[]>([])
    const [tokensHeadset, setTokensHeadset] = useState<EventInfo[]>([])

    const requestStreams = async () => {
        const api = await API()

        const result = await api.eventsList({
            fromTime: DateTime.now().minus({ days: 365 * 10 }).toSeconds(),
            streams: JSON.stringify(['tokens_read', 'tokens_headset'])
        })
        setTokensHeadset(uniqBy(result.events.filter(x => x.streamIds.includes('tokens_headset')), x => x.content))
        setTokensRead(uniqBy(result.events.filter(x => x.streamIds.includes('tokens_read')), x => x.content))
    }

    const [currentActive, setCurrentActive] = useState('headsetAccess')
    const onSelected = (active: string) => {
        setCurrentActive(active)
    }
    
    interface TokenInfo {
        id: string,
        login: string,
        token: string,
        url: string,
    }

    const prepareTestData = async (info: TokenInfo) => {
        const api = await EndpointAPI(info.url)
        await api.batchCall(EventsExample1)
        setTimeout(async () => await api.batchCall(EventsExample2),  1100)
    }
    
    const AccessTokensList: FC<{ onShowInfo?: (info: TokenInfo) => void, events: EventInfo[], title?: string, label?: string}> = ({ onShowInfo, events, title, label }) => {
        const tokenInfo = events.map(x => {
            const url = new URL(x.content)
            return {
                id: x.id,
                login: url.pathname.replaceAll('/', ''),
                token: url.username,
                url: x.content,
            } as TokenInfo
        })
        return (<>
            {tokenInfo.map(x =>
                <ListItem key={x.id} >
                    {title && <Text style={styles.item_token_title}>{title}</Text>}
                    <View style={styles.item_token_container}>
                        <Text style={styles.item_token_label}>Login: </Text><Text style={styles.item_token}>{x.login}</Text>
                    </View>
                    <View style={styles.item_token_container}>
                        <Text style={styles.item_token_label}>Token: </Text><Text style={styles.item_token}>{x.token}</Text>
                    </View>
                    {onShowInfo && <LinkText style={{marginTop: 10}} label={label || "View"} onPress={() => onShowInfo(x)} />}
                </ListItem>
            )}
        </>)
    }

    useEffect(() => {
        requestStreams()
    }, [])


    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("adminHome")} />
            <View style={[styles.content]}>
                <Text style={styles.title} >{lang.admin_list_users}</Text>
                <STabBar2 items={[
                    { label: lang.admin_list_users_headset, value: "headsetAccess" },
                    { label: lang.admin_list_users_view_scores, value: "readAccess" },
                ]}
                    onSelected={onSelected}
                />
                <View style={styles.list}>
                    {currentActive == 'headsetAccess' && <AccessTokensList events={tokensHeadset} title="Headset info" onShowInfo={prepareTestData} label="Load Test Data" />}
                    {currentActive == 'readAccess' && <AccessTokensList onShowInfo={(info) => navigation("performance", true, { apiEndpoint: info.url, title: info.login })} label="View Performance" events={tokensRead} />}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        marginBottom: 15,
        textAlign: "center"
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
    },

    list: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: 'stretch',
        marginTop: 30,
    },

    item_token_title: {
        color: "#48A9C5",
        fontFamily: "SegoeRegular",
        fontSize: 17,
        textAlign: "center",
        marginBottom: 10
    },

    item_token_container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 2,
        marginBottom: 2
    },

    item_token_label: {
        color: "#FFFFFF",
        marginRight: 5,
        fontSize: 14
    },

    item_token: {
        color: "#FFFFFF",
        fontFamily: "SFProSemibold",
        fontSize: 14
    }
});
