import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { HasNavigation } from '../Navigation';
import SenopiApi from '../api/SenopiApi';
import UserProfile from '../api/model/UserProfile';
import HeaderPane from '../elements/HeaderPane';
import SButton2 from '../elements/SButton2';
import SubUserProgressScreen from './SubUserProgressScreen';
import SubUsersList, { ListAction } from './SubUsersList';

interface Props extends HasNavigation {
  source: 'subadmin' | 'user';
  user?: UserProfile;
}

export default ({ user, source = 'user', navigation, style, lang, ...rest }: Props) => {
  const [showCreateUser, setShowCreateUser] = useState(false);

  const onSelectUser = (user: UserProfile) => {
    if (user.type == 'user' || user.type == 'placebo') {
      navigation(SubUserProgressScreen, true, {
        user: user,
        title: [user.firstName, user.lastName].join(' ') || user.username,
      });
    }

    if (user.type == 'subadmin') {
      navigation('subadminHome', true, {
        user: user,
        title: [user.firstName, user.lastName].join(' ') || user.username,
      });
    }

    if (user.type == 'supervisor') {
      navigation('supervisorHome', true, {
        user: user,
        title: [user.firstName, user.lastName].join(' ') || user.username,
      });
    }
  };

  const initCreateUser = async () => {
    const api = await SenopiApi();
    setShowCreateUser((source == 'user' && api.userInfo.type != 'admin') || source == 'subadmin');
  };

  useEffect(() => {
    initCreateUser();
  }, []);

  const createLabel = source == 'user' ? lang.admin_create_user : lang.admin_create_subadmin;

  const actions: Array<ListAction> = showCreateUser
    ? [
        {
          icon: require('../../assets/icon_edit.png'),
          width: 14,
          height: 14,
          action: (user: UserProfile) => navigation('subadminSubadminEdit', true, { user: user }),
        },
      ]
    : [];

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} />
      <View style={[styles.content]}>
        {showCreateUser && (
          <SButton2
            style={styles.create_user}
            label={'+ ' + createLabel}
            onPress={() => navigation('subadminCreateUser', true, { type: source })}
          />
        )}
        <SubUsersList
          user={user}
          source={source}
          style={{ marginTop: 18 }}
          lang={lang}
          {...rest}
          onSelectUser={onSelectUser}
          navigation={navigation}
          actions={actions}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  content: {
    paddingStart: 16,
    paddingEnd: 16,
  },

  list: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    marginTop: 30,
  },

  create_user: {
    marginTop: 23,
  },

  user_profile: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  user_photo: {
    width: 50,
    height: 50,
    borderWidth: 2,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  user_label: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
    flexGrow: 1,
  },

  search: {
    backgroundColor: '#0A100FB2',
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 18,
  },

  search_icon: {
    width: 21,
    height: 21,
    marginRight: 23,
  },

  search_input: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    flexGrow: 1,
  },
});
