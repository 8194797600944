import { useEffect, useState } from 'react';
import { ImageBackground, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { ExerciseInfo, Screen } from '../../api/model/LiveSession';
import UserProfile from '../../api/model/UserProfile';
import SButton from '../../elements/SButton';
import useSocketIO from '../../hooks/useSocketIO';
import { HasNavigation } from '../../Navigation';
import Content from './Content';

interface Props extends HasNavigation {
  exercise: ExerciseInfo;
  user: UserProfile;
}

export default ({ exercise, user, navigation, style, lang }: Props) => {
  const [currentExercise, setExercise] = useState<ExerciseInfo>(exercise);
  const { message } = useSocketIO<ExerciseInfo>(user, 'state');

  useEffect(() => {
    setExercise((prevExercise) => ({ ...prevExercise, ...message }));
  }, [message, setExercise]);

  const getImagePath = (): ImageSourcePropType => {
    if (currentExercise.screen !== Screen.GAME)
      return require('../../../assets/live_session/live_session_background.png');

    try {
      const path = require(`../../../assets/live_session/${currentExercise.background}.png`);
      return path;
    } catch {
      return require('../../../assets/live_session/ATM00510-7680.png');
    }
  };

  const getContent = (): JSX.Element | null => {
    switch (currentExercise.screen) {
      case Screen.CALIBRATION_01:
        return <Content.CalibrationView lang={lang} step={1} />;
      case Screen.CALIBRATION_02:
        return <Content.CalibrationView lang={lang} step={2} />;
      case Screen.POINTER_SELECTION:
        return <Content.ControllerScreen lang={lang} />;
      case Screen.ENVIRONMENT:
        return <Content.SelectionView lang={lang} screen={currentExercise.screen} />;
      case Screen.GAME:
        return <Content.ExerciseInfoView lang={lang} exercise={currentExercise} />;
      case Screen.GOODBYE:
        return <Content.GoodbyeView lang={lang} />;
      case Screen.INSTRUCTIONS:
        return <Content.InstructionsView lang={lang} exerciseInfo={currentExercise.exerciseInfo} />;
      case Screen.MUSIC_SELECTION:
        return <Content.SelectionView lang={lang} screen={currentExercise.screen} />;
      case Screen.OVERVIEW:
        return <Content.SessionOverviewView lang={lang} />;
      case Screen.RESULTS:
        return (
          <Content.ResultsView lang={lang} performanceInfo={currentExercise.performanceInfo} />
        );
      case Screen.TUTORIAL:
        return <Content.TutorialView lang={lang} exerciseDetails={currentExercise.exerciseInfo} />;
      case Screen.WELCOME:
        return <Content.WelcomeView lang={lang} />;
      case Screen.FINAL_OVERVIEW:
        return <Content.FinalView lang={lang} />;
      default:
        return null;
    }
  };

  return (
    <ImageBackground style={[styles.container, style]} source={getImagePath()} resizeMode='cover'>
      <Content.BalloonsImage screen={currentExercise.screen} />
      <View style={styles.content}>
        <Content.UserInfo headset={currentExercise.headsetId} user={user} />
        {getContent()}
      </View>
      <Content.AdditionalInstructions lang={lang} screen={currentExercise.screen} />
      <SButton style={styles.back_button} label={lang.go_back} onPress={() => navigation('..')} />
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  content: {
    paddingStart: 8,
    paddingEnd: 8,
    flex: 1,
  },

  back_button: {
    position: 'absolute',
    bottom: 16,
    left: 60,
    right: 60,
  },
});
