import { StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import HeaderPane from '../elements/HeaderPane';
import { HasNavigation } from '../Navigation';
import SubUsersList from './SubUsersList';

interface SelectUserProps extends HasNavigation {
    onSelectUser: (user: UserProfile) => void
    source: 'subadmin' | 'user'
    user?: UserProfile
}

export default ({ user, onSelectUser, source, navigation, style, lang, ...rest }: SelectUserProps) => {

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={[styles.title]} >{lang.users_select}</Text>
                <SubUsersList
                    user={user}
                    source={source}
                    lang={lang}
                    {...rest}
                    onSelectUser={onSelectUser}
                    navigation={navigation} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        marginBottom: 15,
        textAlign: "center"
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
    },
});
