import { useState } from 'react';
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';
import { getUsername } from '../Utils';
import SuperviserEditUser from './SuperviserEditUser';
import SuperviserUserInfo from './SuperviserUserInfo';

const screenHeight = Dimensions.get('screen').height;

const UserProfileInfo = ({
  user,
  actions,
}: {
  user: UserProfile;
  lang: any;
  actions?: Array<ListAction>;
}) => {
  return (
    <View style={styles.user_profile}>
      <View style={{ flexGrow: 1 }}>
        <Text style={styles.user_label}>{getUsername(user)}</Text>
      </View>
      {user.token &&
        actions?.map((a, i) => (
          <TouchableOpacity style={[styles.action, a.style]} key={i} onPress={() => a.action(user)}>
            <Image style={{ width: a.width, height: a.height }} source={a.icon} />
          </TouchableOpacity>
        ))}
    </View>
  );
};

interface UsersListProps extends HasNavigation {
  user?: UserProfile;
  usersFetch: UserProfile[];
  scrollStyle?: ViewStyle;
}

interface ListAction {
  icon: ImageSourcePropType;
  width: number;
  height: number;
  style?: StyleProp<ViewStyle>;
  action: (user: UserProfile) => void;
}

export default ({ usersFetch, lang, dialog, navigation, scrollStyle }: UsersListProps) => {
  const [text, setText] = useState<string>('');

  const filter = (x: UserProfile) => {
    return (
      x.firstName?.match(`.*${text}.*`) ||
      x.lastName?.match(`.*${text}.*`) ||
      x.username?.match(`.*${text}.*`)
    );
  };

  const updateUser = async (user: UserProfile) => {
    dialog.showLoading();
    const api = await SenopiApi();
    await api.updateUserProfile(user);
    const index = usersFetch.findIndex((item: UserProfile) => item.username == user.username);
    if (index > -1) {
      usersFetch[index] = user;
    }
    navigation('..', false, { usersFetch: usersFetch });
    dialog.hideLoading();
  };

  const actions: Array<ListAction> = [
    {
      icon: require('../../assets/icon_info.png'),
      width: 20,
      height: 20,
      style: { marginRight: 10 },
      action: (user: UserProfile) => navigation(SuperviserUserInfo, true, { user: user }),
    },
    {
      icon: require('../../assets/icon_edit.png'),
      width: 14,
      height: 14,
      action: (user: UserProfile) =>
        navigation(SuperviserEditUser, true, {
          user: user,
          isEdit: true,
          closeScreen: () => navigation('..'),
          onSave: updateUser,
        }),
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.search}>
        <Image source={require('../../assets/icon_search.png')} style={styles.search_icon} />
        <TextInput
          style={styles.search_input}
          placeholder={lang.users_search}
          onChangeText={setText}
        />
      </View>
      <ScrollView style={[styles.list, scrollStyle]} showsVerticalScrollIndicator={false}>
        {usersFetch.filter(filter).map((x) => (
          <ListItem key={x.username}>
            <UserProfileInfo user={x} lang={lang} actions={actions} />
          </ListItem>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    marginTop: 18,
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  list: {
    flexGrow: 1,
    height: screenHeight * 0.7,
  },

  create_user: {
    marginTop: 23,
    marginBottom: 18,
  },

  user_profile: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },

  user_photo: {
    width: 50,
    height: 50,
    borderWidth: 2,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  user_label: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
    textAlign: 'left',
    flexGrow: 1,
  },

  user_sublabel: {
    color: '#FFFFFF',
    opacity: 0.5,
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
    flexGrow: 1,
  },

  search: {
    backgroundColor: '#0A100FB2',
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 18,
  },

  search_icon: {
    width: 21,
    height: 21,
    marginRight: 23,
  },

  search_input: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    flexGrow: 1,
  },

  action: {
    width: 26,
    height: 26,
    borderRadius: 26,
    backgroundColor: '#48A9C5',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export { ListAction };
