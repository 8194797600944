import { FC, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TextInput, View } from 'react-native';
import SenopiApi from './api/SenopiApi';
import CheckBoxLabel from './elements/CheckBoxLabel';
import HeaderPane from './elements/HeaderPane';
import SButton from './elements/SButton';
import SDatePicker from './elements/SDatePicker';
import { HasNavigation } from './Navigation';


const AdverseReportScreen: FC<HasNavigation> = ({ dialog, navigation, lang, setLang, style }) => {

    const [report, setReport] = useState<Set<string>>(new Set());
    const [reportOther, setReportOther] = useState<string>("");
    const [reportDate, setReportDate] = useState<Date>();

    const reportValues: { [key: string]: string } = lang.report_items

    const onReport = (value: string, checked: boolean) => {
        checked ? report?.add(value) : report?.delete(value)
        setReport(new Set(report))
        if ("Other" != value) onReport("Other", reportOther.length > 0)
    }

    const sendReport = async () => {
        dialog.showDialog((<ActivityIndicator color={"#FFFFFF"} />), { backgroundColor: "" })
        const api = await SenopiApi()
        await api.reportSend({
            date: reportDate,
            username: api.userInfo.username,
            effectValue: [...report].join(', '),
            effectText: reportOther
        })
        dialog.hideDialog()
        navigation("..")
    }

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={styles.title} >{lang.report_title}</Text>
                <Text style={[styles.subtitle, { marginTop: 25, marginBottom: 30 }]} >{lang.report_subtitle}</Text>
                {Object.keys(reportValues).map(x =>
                    <CheckBoxLabel key={x} label={reportValues[x]} style={{ marginTop: 11, justifyContent: 'flex-start', width: 250 }} styleLabel={{ marginStart: 20 }} isChecked={report.has(x)} onCheckedChange={(checked) => onReport(x, checked)} />)
                }
                <View style={styles.input_other_row}>
                    <CheckBoxLabel label={lang.report_other + ":"} style={{ marginTop: 11, justifyContent: 'flex-start' }} styleLabel={{ marginStart: 20 }} isChecked={report.has("Other")} onCheckedChange={(checked) => onReport("Other", checked)} />
                    <TextInput style={styles.input_other} onChangeText={setReportOther} onFocus={() => onReport("Other", true)} />
                </View>
                <Text style={[styles.subtitle, { marginTop: 25, marginBottom: 30 }]} >{lang.report_date}</Text>
                <SDatePicker style={{ marginBottom: 25 }} value={reportDate} onDateChange={setReportDate} navigation={navigation} lang={lang} dialog={dialog} setLang={setLang} />
                <View style={{ flexGrow: 1 }} />
                <SButton style={{ marginBottom: 10 }} label={lang.report_save} onPress={sendReport} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        textAlign: "center"
    },

    subtitle: {
        color: "#FFFFFF",
        opacity: 0.6,
        fontSize: 15,
        fontFamily: "SegoeRegular",
        textAlign: "center",
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
        alignItems: 'center'
    },

    input_other_row: {
        flexDirection: 'row',
        width: 250
    },

    input_other: {
        marginStart: 11,
        marginTop: 14,
        minWidth: "inherit",
        fontFamily: "SegoeRegular",
        fontSize: 18,
        color: "#FFFFFF",
        borderBottomColor: "#FFFFFF",
        borderBottomWidth: 1,
    }
});

export default AdverseReportScreen