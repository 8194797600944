import { FC, ReactChild } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SectionView from './SectionView';

interface DialogProps {
  isVisible: boolean;
  onDismiss: () => void;
}

export const Dialog: FC<DialogProps> = (props) => {
  const { children, isVisible: isVisible, onDismiss } = props;
  const { top } = useSafeAreaInsets();

  if (!isVisible) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={() => onDismiss()}>
      <View style={[styles.container]}>
        <SectionView style={styles.modal}>{children as ReactChild}</SectionView>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000090',
    paddingHorizontal: 20,
    zIndex: 100,
  },
  modal: {
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: '#000',
    opacity: 1,
    minWidth: '90%',
  },
});
