import { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ResponseError } from './api/ApiUtils';
import SenopiApi from './api/SenopiApi';
import CheckBoxLabel from './elements/CheckBoxLabel';
import LanguagePicker from './elements/LanguagePicker';
import LoginInput from './elements/LoginInput';
import SButton from './elements/SButton';
import SText from './elements/SText';
import TosDialog from './elements/TosDialog';
import { HasNavigation } from './Navigation';
import { useJsonAsyncStorage } from './Utils';
interface Props extends HasNavigation {}

export default ({ navigation, style, lang, dialog, setLang, ...rest }: Props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [passwordSecure, setPasswordSecure] = useState(true);

  const [allowAccessData, setAllowAccessData] = useState(true);

  const onAccessChecked = () => {
    setAllowAccessData(!allowAccessData);
  };

  const acceptTerms = () => {
    dialog.hideDialog();
    const isAccepted = useJsonAsyncStorage('@terms_accepted');
    isAccepted.setItem(true);
    onLogin1().catch((e) => {
      alert((e as ResponseError).message);
    });
  };

  const onLogin = async () => {
    const isAccepted = await useJsonAsyncStorage('@terms_accepted').getItem();
    if (!isAccepted) {
      dialog.showDialog(
        <TosDialog
          navigation={navigation}
          lang={lang}
          dialog={dialog}
          setLang={setLang}
          onAccepted={acceptTerms}
        />,
      );
    } else {
      acceptTerms();
    }
  };

  const onLogin1 = async () => {
    const api = await SenopiApi();
    dialog.showLoading();
    try {
      const loginUser = await api.login({
        appId: 'senopi-med',
        username: login,
        password: password,
        allowAccess: allowAccessData,
      });

      const user = api.userInfo;
      if(user?.type) {
        api.userInfo.language = lang.lang.toLowerCase()
        api.updateAccount(api.userInfo)
      }

      dialog.hideLoading();

      if (allowAccessData && loginUser.token) {

        if (loginUser.type == 'subadmin' || loginUser.type == 'admin') {
          navigation('subadminHome', false, {user: loginUser});
        } else if (loginUser.type == 'supervisor') {
          navigation('supervisorHome');
        } else {
          navigation('home', false, {user: loginUser});
        }
      } else if (!allowAccessData) {
        alert(lang.login_allow_request);
      }
    } catch (e) {
      dialog.hideLoading();
      alert(lang.login_wrong);
    }
  };

  const onForgotPassword = async () => {
    navigation('resetPassword', true);
  };

  return (
    <View style={[styles.container, style]}>
      <LanguagePicker lang={lang} setLang={setLang} navigation={navigation} dialog={dialog} />
      <Image
        source={require('../assets/logo_main.png')}
        style={{ width: 198, height: 50, marginTop: 48 }}
      />
      <Text style={styles.title}>{lang.login_title}</Text>
      <Text style={styles.subtitle}>{lang.login_subtitle}</Text>
      <View style={styles.form}>
        <LoginInput
          style={{ marginBottom: 35 }}
          onChangeText={setLogin}
          label={lang.login_username}
          textContentType='username'
          iconWidth={13}
          iconHeight={15}
        />
        <LoginInput
          onChangeText={setPassword}
          label={lang.login_password}
          icon={require(passwordSecure
            ? '../assets/icon_password.png'
            : '../assets/icon_password_open.png')}
          onIconPress={() => setPasswordSecure(!passwordSecure)}
          textContentType='password'
          secureTextEntry={passwordSecure}
          iconWidth={17}
          iconHeight={11}
        />
        <CheckBoxLabel
          label={lang.login_allow_access}
          isChecked={allowAccessData}
          style={{ marginTop: 15 }}
          onCheckedChange={onAccessChecked}
        />
        <SButton style={styles.login_button} label={lang.login_sign_in} onPress={onLogin} />
        <TouchableOpacity style={styles.reset} onPress={onForgotPassword}>
          <SText center style={styles.reset_text}>
            {lang.reset_password}
          </SText>
        </TouchableOpacity>
      </View>
      <View style={{ flexGrow: 1 }} />
      <Image
        source={require('../assets/logo_developed_by.png')}
        resizeMode='contain'
        style={{ width: 97, height: 46, marginBottom: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#48A9C5',
    fontSize: 36,
    fontFamily: 'SegoeRegular',
    marginTop: 42,
  },

  subtitle: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 17,
    fontFamily: 'SegoeLight',
  },

  form: {
    width: 260,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 84,
    marginBottom: 69,
  },

  login_button: {
    marginTop: 86,
    marginBottom: 11,
  },

  password_forgot: {
    color: '#FFFFFF',
    fontSize: 17,
    fontFamily: 'SegoeLight',
    textAlign: 'center',
  },

  reset: {},
  reset_text: {
    color: '#FFFFFF',
    textDecorationLine: 'underline',
    fontSize: 18,
    opacity: 0.8,
  },
});
