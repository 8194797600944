import { StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import HeaderPane from '../elements/HeaderPane';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
import PerformanceScreen from '../PerformanceScreen';
import ProgressView from '../view/ProgressView';

interface Props extends HasNavigation {
    title?: string,
    user: UserProfile
}

export default ({ title, user, navigation, style, lang, ...rest }: Props) => {

    const onPerformance = async () => {
        navigation(PerformanceScreen, true, { user: user, title: user.username })
    }

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={[styles.title]} >{title || lang.progress_main_title}</Text>
                <ProgressView navigation={navigation} lang={lang} user={user} {...rest} />
                {user.type == 'user' && <SButton
                    label={lang.home_performance}
                    style={{ marginTop: 20, marginBottom: 20, maxWidth: 200 }}
                    onPress={onPerformance}
                />}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
        alignItems: "center"
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        marginBottom: 15,
        textAlign: "center"
    },
});
