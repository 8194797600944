import { useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import LoginInput from '../elements/LoginInput';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
interface Props extends HasNavigation {
    user?: UserProfile
}

export default ({ user, dialog, lang, navigation, style, ...rest }: Props) => {

    const [password, setPassword] = useState<string>()
    const [newPassword, setNewPassword] = useState<string>()
    const [newPassword2, setNewPassword2] = useState<string>()

    const onSave = async () => {
        if(newPassword != newPassword2) {
            alert(lang.user_password_error_no_match)
            return;
        }

        dialog.showDialog((<ActivityIndicator color={"#FFFFFF"} />), { backgroundColor: "" })
        try {
            const api = await SenopiApi()
            await api.updatePassword({
                oldPassword: password,
                newPassword: newPassword,
            })
            
            navigation("..")
        } catch (e) {
            console.error(e)
            return;
        }
        dialog.hideDialog()
    }

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} style={{ width: "100%" }} />
            <Text style={[styles.title]} >{lang.create_title}</Text>
            <View style={styles.form}>
                <LoginInput
                    style={{ marginTop: 26 }}
                    labelStyle={styles.label_style}
                    onChangeText={setPassword}
                    value={password}
                    label={lang.user_password + '*'} />
                <LoginInput
                    style={{ marginTop: 26 }}
                    labelStyle={styles.label_style}
                    onChangeText={setNewPassword}
                    value={newPassword}
                    label={lang.user_password_new + '*'} />

                <LoginInput
                    style={{ marginTop: 26 }}
                    labelStyle={styles.label_style}
                    onChangeText={setNewPassword2}
                    value={newPassword2}
                    label={lang.user_password_new2 + '*'} />

                <View style={{ flexGrow: 1 }} />
                <SButton style={styles.login_button} label={lang.create_submit} onPress={onSave} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: "100%",
        flex: 1,
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 20
    },

    form: {
        width: 314,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: 'center',
        marginTop: 40,
    },

    login_button: {
        width: 256,
        marginTop: 31,
        marginBottom: 11
    },

    label_style: {
        color: "#FFFFFF",
        fontSize: 18,
        fontFamily: "SegoeRegular",
        opacity: 0.8
    },

});
