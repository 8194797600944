import { FC, useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Exercize, { ExercizeData } from './api/model/Exercise';
import UserProfile from './api/model/UserProfile';
import SenopiApi from './api/SenopiApi';
import HeaderPane from './elements/HeaderPane';
import ProfileInfo from './elements/ProfileInfo';
import ProfileStatsAverage from './elements/ProfileStatsAverage';
import ProfileStatsItem from './elements/ProfileStatsItem';
import STabBar3 from './elements/STabBar3';
import { units } from './model/StatsModel';
import { HasNavigation } from './Navigation';


interface PerformanceProps extends HasNavigation {
    title?: string,
    user?: UserProfile,
}

const ProfileStatsItem2: FC<{ exercise?: ExercizeData, unit: units, title: string, lang: { [key: string]: any } }> = ({ exercise, unit, title, lang, ...rest }) => {

    return (<ProfileStatsItem lang={lang} title={title} stats={{
        last: exercise?.lastValue || 0,
        average: exercise?.value || 0,
        total: (exercise && exercise?.count * exercise?.value) || 0,
        isUp: exercise && exercise?.lastValue > exercise?.value,
        unit: unit
    }} />)
}

export default ({ title, user, navigation, lang, style, ...rest }: PerformanceProps) => {

    const [physical, setPhysical] = useState<Exercize | undefined>()
    const [memory, setMemory] = useState<Exercize | undefined>()
    const [focus, setFocus] = useState<Exercize | undefined>()
    const [switchX, setSwitchX] = useState<Exercize | undefined>()

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')

    const dtValue = (ex: Exercize | undefined, type: String) => { return ex?.data?.filter(x => x.type == type)[0] }
    const exValue = (ex: Exercize | undefined, type: String) => { return dtValue(ex, type)?.value || 0 }

    const overviewPhysical: Array<{ value: number | undefined, unit: units, label: string }> = [
        {
            value: exValue(physical, "SessionTime"),
            unit: 'time_duration',
            label: lang.profile_stats_average_time_spent
        },
        {
            value: exValue(physical, "CorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_correct
        },

        {
            value: undefined,
            unit: "count",
            label: ''
        },
    ]

    //-                'physical_SessionTime',
    // -                'physical_Correct',
    // -                'focus_SessionTime',
    // -                'focus_CorrectlyCaught',
    // -                'focus_IncorrectlyCaught',
    // -                'memory_SessionTime',
    // -                'memory_CorrectlyCaught',
    // -                'memory_IncorrectlyCaught',
    // -                'switch_SessionTime',
    // -                'switch_Correct',
    // -                'switch_Incorrect',

    const overviewFocus: Array<{ value: number, unit: units, label: string }> = [
        {
            value: exValue(focus, "SessionTime"),
            unit: "time_duration",
            label: lang.profile_stats_average_time_spent
        },
        {
            value: exValue(focus, "CorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_correct
        },
        {
            value: exValue(focus, "IncorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_incorrect
        },
    ]

    const overviewMemory: Array<{ value: number, unit: units, label: string }> = [
        {
            value: exValue(memory, "SessionTime"),
            unit: "time_duration",
            label: lang.profile_stats_average_time_spent
        },
        {
            value: exValue(memory, "CorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_correct
        },
        {
            value: exValue(memory, "IncorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_incorrect
        },
    ]

    const overviewSwitch: Array<{ value: number, unit: units, label: string }> = [
        {
            value: exValue(switchX, "SessionTime"),
            unit: "time_duration",
            label: lang.profile_stats_average_time_spent
        },
        {
            value: exValue(switchX, "CorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_correct
        },
        {
            value: exValue(switchX, "IncorrectlyCaught"),
            unit: "count",
            label: lang.profile_stats_average_incorrect
        },
    ]

    const requestStreams = async () => {
        const api = await SenopiApi(user)
        const exercises = await api.exercises()
        const map = new Map(exercises.map(x => [x.exercise, x]))
        setPhysical(map.get("physical"))
        setMemory(map.get("memory"))
        setSwitchX(map.get("switch"))
        setFocus(map.get("focus"))

    }

    const [currentActive, setCurrentActive] = useState('overview')
    const onSelected = (active: string) => {
        setCurrentActive(active)
    }

    const Overview = () => (<>
        <ProfileStatsAverage title={lang.profile_main_physical} stats={overviewPhysical} />
        <ProfileStatsAverage title={lang.profile_main_focus} stats={overviewFocus} />
        <ProfileStatsAverage title={lang.profile_main_switch} stats={overviewSwitch} />
        <ProfileStatsAverage title={lang.profile_main_memory} stats={overviewMemory} />
    </>)

    const Physical = () => (<>
        <ProfileStatsItem2 lang={lang} exercise={dtValue(physical, "SessionTime")} unit='time_duration' title={lang.profile_stats_task_time_spent} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(physical, "CorrectlyCaught")} unit='count' title={lang.profile_stats_task_correct} />
    </>)

    const Focus = () => (<>
        <ProfileStatsItem2 lang={lang} exercise={dtValue(focus, "SessionTime")} unit='time_duration' title={lang.profile_stats_task_time_spent} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(focus, "CorrectlyCaught")} unit='count' title={lang.profile_stats_task_correct} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(focus, "IncorrectlyCaught")} unit='count' title={lang.profile_stats_task_incorrect} />
    </>)

    const Switch = () => (<>
        <ProfileStatsItem2 lang={lang} exercise={dtValue(switchX, "SessionTime")} unit='time_duration' title={lang.profile_stats_task_time_spent} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(switchX, "CorrectlyCaught")} unit='count' title={lang.profile_stats_task_correct} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(switchX, "IncorrectlyCaught")} unit='count' title={lang.profile_stats_task_incorrect} />
    </>)

    const Memory = () => (<>
        <ProfileStatsItem2 lang={lang} exercise={dtValue(memory, "SessionTime")} unit='time_duration' title={lang.profile_stats_task_time_spent} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(memory, "CorrectlyCaught")} unit='count' title={lang.profile_stats_task_correct} />
        <ProfileStatsItem2 lang={lang} exercise={dtValue(memory, "IncorrectlyCaught")} unit='count' title={lang.profile_stats_task_incorrect} />
    </>)

    useEffect(() => {
        requestStreams()
    }, [])

    const tabStyle = [styles.value_label, styles.tab_item]
    if (currentActive == "overview") tabStyle.push(styles.tab_item_active)
    const generalStyles = [styles.current_block]
    if (currentActive == "overview") generalStyles.push(styles.current_active)

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={styles.title} >{title || lang.profile_main_title}</Text>
                <ProfileInfo lang={lang} navigation={navigation} user={
                    {
                        fullName: (firstName + ' ' + lastName).trim(),
                        statsSwitch: switchX?.count || 0,
                        statsPhysical: physical?.count || 0,
                        statsFocus: focus?.count || 0,
                        statsMemory: memory?.count || 0,
                    }
                }
                    {...rest}
                    style={{ marginBottom: 33, marginTop: 26, width: "90%", alignSelf: "center" }} />
                <TouchableOpacity style={generalStyles} onPress={() => setCurrentActive("overview")} >
                    <Text style={tabStyle}>{lang.profile_main_general_overview}</Text>
                </TouchableOpacity>
                <STabBar3
                    isActive={currentActive != "overview"}
                    onSelected={onSelected}
                    selected={currentActive}
                    items={new Map([
                        ["overview", lang.profile_main_select],
                        ["physical", lang.profile_main_physical],
                        ["focus", lang.profile_main_focus],
                        ["switch", lang.profile_main_switch],
                        ["memory", lang.profile_main_memory],
                    ])}
                />
                <View style={styles.stats_list}>
                    {currentActive == 'overview' && <Overview />}
                    {currentActive == 'physical' && <Physical />}
                    {currentActive == 'focus' && <Focus />}
                    {currentActive == 'switch' && <Switch />}
                    {currentActive == 'memory' && <Memory />}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        textAlign: "center"
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
    },

    stats_list: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: 'stretch',
        marginTop: 30,
    },

    current_block: {
        margin: 14,
        paddingStart: 14,
        paddingTop: 5,
        paddingEnd: 14,
        paddingBottom: 6,
        borderRadius: 18,
        borderColor: "#FFFFFFB2",
        borderWidth: 1,
    },

    current_active: {
        backgroundColor: "#48A9C5",
        borderColor: "#48A9C5",
    },

    tab_item: {
        color: "#FFFFFF80",
        fontFamily: "SegoeRegular",
        fontSize: 15,
        textAlign: "center"
    },

    tab_item_active: {
        color: "#FFFFFF",
    },

    value_label: {
        color: "#FFFFFF",
        fontFamily: "SegoeRegular",
        fontSize: 15
    },

});
