import { StyleSheet, Text, View } from 'react-native';
import UserProfile from './api/model/UserProfile';
import HeaderPane from './elements/HeaderPane';
import LinkText from './elements/LinkText';
import { HasNavigation } from './Navigation';
import RemindersScreen from './RemindersScreen';
import ProgressView from './view/ProgressView';

interface Props extends HasNavigation {
    title?: string
    user?: UserProfile
}

export default ({ title, user, navigation, lang, style, ...rest }: Props) => {

    return (
        <View style={[styles.container, style]}>
            <HeaderPane onBackPressed={() => navigation("..")} />
            <View style={[styles.content]}>
                <Text style={styles.title} >{title || lang.progress_main_title}</Text>
                <ProgressView user={user} navigation={navigation} lang={lang}  {...rest}  />
                <LinkText label={lang.progress_main_edit_schedule} style={{ marginTop: 33, marginBottom: 33 }} onPress={() => navigation(RemindersScreen, true)} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        textAlign: "center",
        marginBottom: 26
    },

    content: {
        paddingStart: 16,
        paddingEnd: 16,
    },
});
