import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { SystemZone } from "luxon";
import { FC, useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { debounce } from "throttle-debounce";
import { Reminder } from "./API";
import SenopiApi from "./api/SenopiApi";
import CheckedButton from "./elements/CheckButton";
import HeaderPane from "./elements/HeaderPane";
import TimeInput from "./elements/TimeInput";
import { HasNavigation } from "./Navigation";

const initFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyATx5K_AWhFCAmOiqIVEUlctHHrjurrsRQ",
        authDomain: "senopimed-push-test.firebaseapp.com",
        projectId: "senopimed-push-test",
        storageBucket: "senopimed-push-test.appspot.com",
        messagingSenderId: "702738043250",
        appId: "1:702738043250:web:dd6e91ffdcda5749ad0deb",
    }

    // Initialize Firebase
    return initializeApp(firebaseConfig)
}

const getCloudToken = async () => {
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging()
    return await getToken(messaging, {
        vapidKey: 'BLUWoCjicLAwMJ05K_ChWWqqRojZJkb3FNLRhf9937eBqlxo6hAknSfsunzDz4jo3l_JWwBbOIorDOCRFSAn-mI',
        serviceWorkerRegistration: await navigator.serviceWorker.ready
    })
}

const RemindersScreen: FC<HasNavigation> = ({ navigation, dialog, style, lang }) => {

    initFirebase()
    const weekdays: { [key: string]: string } = lang.reminders_weekdays

    const [stateWeekdays, setWeekdays] = useState<Map<string, Reminder>>(new Map())


    const onWeekday = (value: string, checked: boolean) => {
        const current: Reminder = stateWeekdays.has(value) ? stateWeekdays.get(value)! : { hour: new Date().getHours(), minutes: new Date().getMinutes() } as Reminder
        current.enabled = checked
        stateWeekdays.set(value, current)
        setWeekdays(new Map(stateWeekdays))
        saveReminders()
    }

    const onTimeChange = (weekday: string, hour?: number, minutes?: number) => {
        const current: Reminder = stateWeekdays.has(weekday) ? stateWeekdays.get(weekday)! : {} as Reminder
        current.hour = Number.isInteger(hour) ? hour : undefined
        current.minutes = Number.isInteger(minutes) ? minutes : undefined
        current.timezone = new SystemZone().name
        stateWeekdays.set(weekday, current)
        setWeekdays(new Map(stateWeekdays))
        saveReminders()
    }

    const loadReminders = async () => {
        const api = await SenopiApi()
        const weekdays = (await api.scheduleGet())?.schedule
        if (weekdays) {
            setWeekdays(new Map(Object.entries(weekdays)))
        }
    }

    const saveReminders = debounce(500, async () => {
        try {
            const token = await getCloudToken()
            if (token) {
                console.log(token)
                await navigator.serviceWorker.ready;
                Notification.requestPermission().then(async (result) => {
                    if (result === 'granted') {
                        try {
                            const api = await SenopiApi()
                            await api.scheduleAdd(Object.fromEntries(stateWeekdays))
                            await api.scheduleAllow()
                            console.log('Reminders saved!');
                        } catch (e) {
                            console.error(`Reminders failed:\n${e}`);
                        }
                    }
                })
            } else {
                console.log("Token not available!")
            }
        } catch (e) {
            console.log(e)
        }
    })

    const load = async () => {
        dialog.showLoading()
        try {
            await Promise.all([
                loadReminders(),
                initFirebase()
            ])
        } catch(e) {
            console.log(e)
        }
        dialog.hideLoading()
    }

    useEffect(() => {
        load()
    }, [])

    return <View style={[styles.container, style]}>
        <HeaderPane onBackPressed={() => navigation("..")} style={{ width: "100%" }} />
        <Text style={[styles.title]} >{lang.reminders_title}</Text>
        <Text style={[styles.subtitle, { marginTop: 25, marginBottom: 30 }]} >{lang.reminders_subtitle}</Text>
        <ScrollView style={{ height: "100%" }} >
            {Object.keys(weekdays).map(x =>
                <View key={x} style={styles.weekRow}>
                    <CheckedButton label={weekdays[x]} isChecked={stateWeekdays?.get(x)?.enabled} onCheckedChange={(checked) => onWeekday(x, checked)} style={styles.weekButton} />
                    {stateWeekdays?.get(x)?.enabled && <TimeInput style={{ alignSelf: "center" }} onTimeChange={(hour, minutes) => onTimeChange(x, hour, minutes)} hour={stateWeekdays?.get(x)?.hour} minutes={stateWeekdays?.get(x)?.minutes} />}
                </View>

            )}
        </ScrollView>
        <Text style={[styles.subtitle, { marginBottom: 30 }]} >{lang.reminders_time}</Text>
        <View style={{ flexGrow: 1 }} />
    </View>
}


const styles = StyleSheet.create({
    container: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        // alignItems: 'stretch',
        justifyContent: 'center',
        alignItems: "center",
        flex: 1
    },

    title: {
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "SegoeRegular",
        marginTop: 15,
        textAlign: "center"
    },

    subtitle: {
        color: "#FFFFFF",
        opacity: 0.6,
        fontSize: 15,
        fontFamily: "SegoeRegular",
        textAlign: "center",
    },

    weekButton: {
        width: 110,
        height: 50,
        marginEnd: 13
    },

    weekRow: {
        flexDirection: "row",
        marginBottom: 20,
        width: 281
    },

    submitButton: {
        minWidth: 256,
        marginTop: 26,
        marginBottom: 36
    },

});

export default RemindersScreen